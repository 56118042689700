import React from 'react';
import './NFTCollection.css';
import NFT from './images/NFT.jpg';

const nftData = [
  // Add your NFT data here
  { id: 1, image: NFT, title: 'My Collection', floor: '0.010 ETH', value: '0.010 ETH' },
  { id: 2, image: NFT, title: 'My Collection', floor: '0.010 ETH', value: '0.010 ETH' },
  { id: 3, image: NFT, title: 'My Collection', floor: '0.010 ETH', value: '0.010 ETH' },
  { id: 4, image: NFT, title: 'My Collection', floor: '0.010 ETH', value: '0.010 ETH' },
  { id: 5, image: NFT, title: 'My Collection', floor: '0.010 ETH', value: '0.010 ETH' },
  { id: 6, image: NFT, title: 'My Collection', floor: '0.010 ETH', value: '0.010 ETH' },
  { id: 7, image: NFT, title: 'My Collection', floor: '0.010 ETH', value: '0.010 ETH' },

];

const NFTCollection = () => {
  return (
    <div className="nft-page">
      <div className="nft-header">
        <input type="text" className="search-bar" placeholder="Search" />
        <button className="filter-button">Filter</button>
      </div>
      <div className="nft-grid">
        {nftData.map((nft) => (
          <div key={nft.id} className="nft-card">
            <img src={nft.image} alt={nft.title} className="nft-image" />
            <div className="nft-details">
              <h3>{nft.title}</h3>
              <p>{nft.floor} <span>Floor</span></p>
              <p>{nft.value} <span>Total Value</span></p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NFTCollection;
