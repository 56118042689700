import React from 'react';
import './Chart.css';  // Make sure this path is correct based on your project structure

const DexScreenerEmbed = () => {
    return (
        <div className='Dex'>
            <div id="dexscreener-embed">
                <iframe
                    src="https://dexscreener.com/base/0x4CB43ce7546581D670E20ffc8885A2C4FE06B74b?embed=1&theme=dark&trades=0&info=0"
                    title="DexScreener"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );
};

export default DexScreenerEmbed;
