import React, { useState, useEffect, useCallback } from 'react';
import { ethers } from 'ethers';
import { useGateway, GatewayStatus } from "@civic/ethereum-gateway-react";
import verification_ABI from "./Verification_ABI.json";

const WalletConnect = ({ onWalletConnected }) => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [verified, setVerified] = useState(false);
  const [userAddress, setUserAddress] = useState('');
  const [walletConnected, setWalletConnected] = useState(false);
  const [whitelistAddress, setWhitelistAddress] = useState(false);
  const { requestGatewayToken, gatewayStatus } = useGateway();

  const contractAddress = "0x823f25360264770cc64866881ba65f134c4c9a07";

  const connectWallet = async () => {
    if (isConnecting) return;
    setIsConnecting(true);
    try {
      if (!window.ethereum) {
        throw new Error('MetaMask is not installed');
      }

      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const accounts = await provider.listAccounts();
      if (accounts.length === 0) throw new Error("No account is connected");
      setUserAddress(accounts[0]);
      console.log("Wallet connected:", accounts[0]);
      onWalletConnected(signer);
      const needsVerification = await checkVerification(provider, accounts[0]);
      console.log("Verification needed:", needsVerification);
      setVerified(!needsVerification);
      setWalletConnected(true);

      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('disconnect', handleDisconnect);
    } catch (error) {
      console.error('Error connecting to wallet:', error);
    } finally {
      setIsConnecting(false);
    }
  };

  const checkVerification = async (provider, userAddress) => {
    try {
      if (!ethers.isAddress(contractAddress)) {
        throw new Error("Invalid contract address");
      }

      const contract = new ethers.Contract(contractAddress, verification_ABI, provider);

      const isVerified = await contract.isUserVerified(userAddress);

      console.log(`User verification status for ${userAddress}:`, isVerified);

      return !isVerified;
    } catch (error) {
      console.error('Error checking verification status:', error);
      return false;
    }
  };

  const handleAccountsChanged = useCallback((accounts) => {
    if (accounts.length === 0) {
      handleDisconnect();
    } else {
      setUserAddress(accounts[0]);
      setWalletConnected(false);
      setVerified(false);
      connectWallet();
    }
  }, [connectWallet]);

  const handleDisconnect = useCallback(() => {
    setWalletConnected(false);
    setVerified(false);
    setUserAddress('');
    console.log('Wallet disconnected');
  }, []);

  const activateAccount = async () => {
    try {
      if (!window.ethereum) {
        throw new Error('MetaMask is not installed');
      }
  
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(contractAddress, verification_ABI, signer);
  
      const tx = await contract.verifyUser({
        gasLimit: 121000
      });  

      await tx.wait();
      
      console.log("Account activated");
      setWhitelistAddress(true);
    } catch (error) {
      console.error('Error activating account:', error);
      if (error.code === -32603 && error.data) {
        console.error('Revert reason:', error.data.message || 'Unknown reason');
      } else {
        console.error('Unhandled error:', error.message);
      }
    }
  };  

  const updateCivicStatus = useCallback(() => {
    switch (gatewayStatus) {
      case GatewayStatus.ACTIVE:
        setVerified(true);
        console.log('Civic status is ACTIVE');
        break;
      default:
        setVerified(false);
        console.log('Civic status is UNKNOWN');
    }
  }, [gatewayStatus]);

  useEffect(() => {
    updateCivicStatus();
  }, [gatewayStatus, walletConnected, updateCivicStatus]);

  useEffect(() => {
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        window.ethereum.removeListener('disconnect', handleDisconnect);
      }
    };
  }, [handleAccountsChanged, handleDisconnect]);

  return (
    <div className="modal">
      {!walletConnected && (
        <button className="wallet-info" onClick={connectWallet} disabled={isConnecting}>
          {isConnecting ? 'Connecting to wallet...' : 'Connect Wallet'}
        </button>
      )}
      {walletConnected && !verified && (
        <button className="wallet-info" onClick={requestGatewayToken} disabled={isConnecting}>
          Verify Identity for Access
        </button>
      )}
      {walletConnected && verified && !whitelistAddress && (
        <button className="wallet-info" onClick={activateAccount} disabled={isConnecting}>
          Activate Your Account
        </button>
      )}
      {walletConnected && verified && whitelistAddress && (
        <p className="wallet-info">
          {`Welcome, ${userAddress}`}
        </p>
      )}
    </div>
  );
};

export default WalletConnect;
