import React, { useState } from 'react';
import './Navbar.css';

function Navbar({ onSelectCategory }) {
  const [activeCategory, setActiveCategory] = useState('Home'); 

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    onSelectCategory(category);
  };

  return (
    <div className="navbar">
      <h1 className="navbar-title">Menu</h1>
      <ul className="navbar-nav">
        <li>
          <button
            className={activeCategory === 'Home' ? 'active' : ''}
            onClick={() => handleCategoryClick('Home')}
          >
            Home
          </button>
        </li>
        <li>
          <button
            className={activeCategory === 'Ecosystem Info' ? 'active' : ''}
            onClick={() => handleCategoryClick('Ecosystem Info')}
          >
            Ecosystem Info
          </button>
        </li>
        <li>
          <button
            className={activeCategory === 'NFT Marketplace' ? 'active' : ''}
            onClick={() => handleCategoryClick('NFT Marketplace')}
          >
            NFT Marketplace
          </button>
        </li>
        <li>
          <button
            className={activeCategory === 'Chart' ? 'active' : ''}
            onClick={() => handleCategoryClick('Chart')}
          >
            Chart
          </button>
        </li>
        <li>
          <button
            className={activeCategory === 'Reward' ? 'active' : ''}
            onClick={() => handleCategoryClick('Reward')}
          >
            Reward
          </button>
        </li>
        <li>
          <button
            className={activeCategory === 'Affiliates' ? 'active' : ''}
            onClick={() => handleCategoryClick('Affiliates')}
          >
            Affiliates
          </button>
        </li>
        <li>
          <button
            className={activeCategory === 'Setting' ? 'active' : ''}
            onClick={() => handleCategoryClick('Setting')}
          >
            Setting
          </button>
        </li>
        <li>
          <button
            className={activeCategory === 'Integration' ? 'active' : ''}
            onClick={() => handleCategoryClick('Integration')}
          >
            Integration
          </button>
        </li>
        <li>
          <button
            className={activeCategory === 'Community' ? 'active' : ''}
            onClick={() => handleCategoryClick('Community')}
          >
            Community
          </button>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
