import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import './Home.css'; 

const Home = () => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null); 
    const stats= {
        totalTokens: 13567,
        totalUsers: 1356,
        totalTransactions: 8567,
        totalActiveDapps: 139
    };
    const announcements = [
      {
        time: "Today 09:00 PM",
        message: "System Update: We've upgraded our transaction processing. Enjoy faster speeds starting now!"
    },
    {
        time: "Today 03:00 PM",
        message: "Feature Spotlight: Have you tried our new dashboard analytics tool? Dive into your data like never before!"
    },
    ];

    const viewAll = () => {
      alert('View all announcements!');
  };

    useEffect(() => {
        const myChartRef = chartRef.current.getContext("2d");

        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        chartInstance.current = new Chart(myChartRef, {
          type: 'line',
          data: {
              labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
              datasets: [
                  {
                      label: "Transactions",
                      data: [12000, 19000, 30000, 47650, 20000, 30000, 45000],
                      backgroundColor: 'rgba(5, 28, 175, 0.3)',
                      borderColor: 'rgba(25, 48, 202, 1)',
                      borderWidth: 3,
                      fill: true,
                      tension: 0.3,
                      pointRadius: 5,        // Default point radius
                      pointHoverRadius: 7,   // Radius on hover
                      pointBackgroundColor: 'rgba(25, 48, 202, 1)', // Customize point color
                      pointBorderColor: 'white',  // Border color of points
                      pointBorderWidth: 2    // Border width of points
                  }
              ]
          },
          options: {
              scales: {
                  y: {
                      beginAtZero: true,
                      ticks: {
                          color: 'white',
                          font: {
                              size: 14 
                          }
                      }
                  },
                  x: {
                      ticks: {
                          color: 'white',
                          font: {
                              size: 14 
                          }
                      }
                  }
              },
              plugins: {
                  legend: {
                      labels: {
                          color: 'white',
                          font: {
                              size: 14 
                          }
                      }
                  },
                  tooltip: {
                      titleColor: 'white', 
                      bodyColor: 'white'
                  }
              },
              maintainAspectRatio: false,
              interaction: {
                  mode: 'nearest',       
                  intersect: false       
              }
          }
      });
      

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, []);

    return (
        <div className="home-dashboard">
          <div className='right-and-left'>
            <div className='image'>
            <h2>Be part of the community by joining our social medias</h2>
            <ul className="example-2">
  <li className="icon-content">
    <a
      href="https://discord.gg/chain-fi" target="_blank" rel="noreferrer"
      aria-label="Discord"
      data-social="Discord"
    >
      <div className="filled"></div>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-discord" viewBox="0 0 16 16">
  <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
</svg>
    </a>
    <div className="tooltip">Discord</div>
  </li>
  <li className="icon-content">
    <a
      href="https://youtube.com/@chainfi" target="_blank" rel="noreferrer"
      aria-label="Youtube"
      data-social="Youtube"
    >
      <div className="filled"></div>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-youtube" viewBox="0 0 16 16">
  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
</svg>    </a>
    <div className="tooltip">Youtube</div>
  </li>
  <li className="icon-content">
    <a
      href="https://www.reddit.com/r/Chain_Fi/?rdt=45142" target="_blank" rel="noreferrer"
      aria-label="Reddit"
      data-social="Reddit"
    >
      <div className="filled"></div>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-reddit" viewBox="0 0 16 16">
  <path d="M6.167 8a.83.83 0 0 0-.83.83c0 .459.372.84.83.831a.831.831 0 0 0 0-1.661m1.843 3.647c.315 0 1.403-.038 1.976-.611a.23.23 0 0 0 0-.306.213.213 0 0 0-.306 0c-.353.363-1.126.487-1.67.487-.545 0-1.308-.124-1.671-.487a.213.213 0 0 0-.306 0 .213.213 0 0 0 0 .306c.564.563 1.652.61 1.977.61zm.992-2.807c0 .458.373.83.831.83s.83-.381.83-.83a.831.831 0 0 0-1.66 0z"/>
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.828-1.165c-.315 0-.602.124-.812.325-.801-.573-1.9-.945-3.121-.993l.534-2.501 1.738.372a.83.83 0 1 0 .83-.869.83.83 0 0 0-.744.468l-1.938-.41a.2.2 0 0 0-.153.028.2.2 0 0 0-.086.134l-.592 2.788c-1.24.038-2.358.41-3.17.992-.21-.2-.496-.324-.81-.324a1.163 1.163 0 0 0-.478 2.224q-.03.17-.029.353c0 1.795 2.091 3.256 4.669 3.256s4.668-1.451 4.668-3.256c0-.114-.01-.238-.029-.353.401-.181.688-.592.688-1.069 0-.65-.525-1.165-1.165-1.165"/>
</svg>
    </a>
    <div className="tooltip">Reddit</div>
  </li>
  <li className="icon-content">
    <a
      href="https://twitter.com/rogueknight_inc" target="_blank" rel="noreferrer"
      aria-label="X"
      data-social="X"
    >
      <div className="filled"></div>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
</svg>
    </a>
    <div className="tooltip">X</div>
  </li>
</ul>
            </div>
            <div className="stats-grid">
                <div className="stat">
                <span className="stat-label">Total Tokens</span>
                    <span className="stat-value">{stats.totalTokens.toLocaleString()}</span>
                </div>
                <div className="stat">
                <span className="stat-label">Total Users</span>
                    <span className="stat-value">{stats.totalUsers.toLocaleString()}</span>
                </div>
                <div className="stat">
                <span className="stat-label">Total Transactions</span>

                    <span className="stat-value">{stats.totalTransactions.toLocaleString()}</span>
                </div>
                <div className="stat">
                <span className="stat-label">Total Active Dapps</span>

                    <span className="stat-value">{stats.totalActiveDapps}</span>
                </div>
            </div>
            </div>
            <div className='right-and-left'>
            <div style={{ width: "600px", height: "300px" }}>
                <canvas ref={chartRef} />
            </div>
            <div className="announcements">
                <h2>Latest Announcements</h2>
                <ul className='annoucements-texts'>
                    {announcements.map((announcement, index) => (
                        <li key={index}>
                            <span className="announcement-time">{announcement.time}</span>
                            <span className="announcement-message">{announcement.message}</span>
                        </li>
                    ))}
                    <div className="btn-conteiner special">
                    <div className="btn-content" onClick={viewAll} aria-label="View all annoucements">
                        <span className="btn-title">View All</span>
                        <span className="icon-arrow">
                        <svg width="66px" height="43px" viewBox="0 0 66 43" version="1.1">
                            <g id="arrow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <path id="arrow-icon-one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                            <path id="arrow-icon-two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                            <path id="arrow-icon-three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
                            </g>
                        </svg>
                        </span> 
                    </div>
                    </div>
                </ul>
            </div>
            </div>
        </div>
    );
};

export default Home;
