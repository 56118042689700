import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { GatewayProvider } from "@civic/ethereum-gateway-react";

const GATEKEEPER_NETWORK = "uniqobk8oGh4XBLMqM68K8M2zNu3CdYX7q5go7whQiv";

const GatewayProviderComponent = ({ children, reload }) => {
  const [wallet, setWallet] = useState({
    address: undefined,
    signer: undefined
  });

  const connectWallet = async () => {
    try {
      if (!window.ethereum) {
        console.log("Ethereum object not found, install Metamask.");
        return;
      }

      // Check for existing accounts
      const accounts = await window.ethereum.request({ method: 'eth_accounts' });

      if (accounts.length > 0) {
        const provider = new ethers.BrowserProvider(window.ethereum);   
        const signer = await provider.getSigner();
        const address = await signer.getAddress();

        setWallet({ address, signer });
      } else {
        console.log('No connected accounts found.');
      }
    } catch (error) {
      console.log('Error connecting to wallet:', error);
    }
  };

  useEffect(() => {
    connectWallet();
  }, [reload]); // Re-run when 'reload' changes

  return (
    <GatewayProvider wallet={wallet} gatekeeperNetwork={GATEKEEPER_NETWORK}>
      {children}
    </GatewayProvider>
  );
};

export default GatewayProviderComponent;
