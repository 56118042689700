import React, { useState } from 'react';
import GatewayProviderComponent from './Civic'; // Assuming Civic.js exports GatewayProviderComponent
import WalletConnect from './WalletConnect';
import "./Header.css";

function Header() {
  const [reload, setReload] = useState(0);

  const onWalletConnected = (signer) => {
    console.log("Wallet has been connected", signer);
    setReload(prev => prev + 1); // Trigger reload by updating state
  };

  return (
    <div className="header">
      <GatewayProviderComponent reload={reload}>
        <WalletConnect onWalletConnected={onWalletConnected} />
      </GatewayProviderComponent>
    </div>
  );
}

export default Header;
