import {React, useEffect, useState} from 'react';
import '../css/HomePage.css';
import Logo from '../images/logo-full.png';
import Rocket from '../images/Rocket.png';
import Deal from '../images/deal.png';
import Excellence from '../images/excellence.png';
import Dedicated from '../images/dedicated.png';
import Passion from '../images/passion.png';
import Transparency from '../images/transparency.png';
import Blocks from '../images/blocks.png';
import Community from '../images/Community.png';
import Project from '../images/project-management.png';
import WP from '../images/Chain-Fi_Whitepaper.pdf';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { version as pdfjsVersion } from 'pdfjs-dist';

import DennisReckermann from './images/DennisReckermann.png';
import DanielAgantem from './images/DanielAgantem.png';
import BarryVanSpronse from './images/BarryVanSpronse.png';
import AlanCharsley from './images/AlanCharsley.png';
import DeboraReckerman from './images/DeboraReckermann.png';

import { useNavigate } from 'react-router-dom';

const HomePage = () => {
    const navigate = useNavigate();

    const handleAboutClick = () => {
      navigate('/about');
    };

    const handleFarmClick = () => {
      navigate('/farm');
    };

    const handleAppClick = () => {
      navigate('/dashboard');
    };

    const handleServicesClick = () => {
      navigate('/services');
    };
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    const [showPDF, setShowPDF] = useState(false);

    const handleButtonClick = (e) => {
        e.preventDefault();
        setShowPDF(!showPDF);
    };
  return (
    <div className="page">
      <div className="banner">
        <h1 className='title'>Welcome to <img className='chain-fi-logo' src={Logo} alt="Rocket" /></h1>
      </div>
      <div className="content">
      <div className="project-container">
        <div className="description">
            <h2 className="title">Chain-Fi Simplifies Crypto and NFT Experiences</h2>
            <p className="content">Discover a seamless crypto and NFT experience with Chain-Fi. Our GM coin revolutionizes transactions across blockchains, covering gas fees. Join our community to earn tokens and shape our decentralized governance.</p>
            <h2 className="title">Transforming the Digital Domain</h2>
            <p className="content">Join us in reshaping the digital landscape through DAG protocols, smart contracts, and cross-chain operability. Together, we're building the future of blockchain technology.</p>
            <h2 className="title">Join Our Mission</h2>
            <p className="content">Be a part of our mission to innovate and expand the possibilities of blockchain technology. Join the Chain-Fi community today and contribute to the decentralized revolution.</p>
        </div>
        <div className="rocket-image">
            <img src={Rocket} alt="Rocket" />
        </div>
        </div>
        <div className='buttons-together'>
        <div className="btn-conteiner">
        <a
                className="btn-content"
                href="#"
                onClick={handleButtonClick}
                aria-label="Read the WhitePaper"
            >            <span className="btn-title">WhitePaper</span>
            <span className="icon-arrow">
            <svg width="66px" height="43px" viewBox="0 0 66 43" version="1.1">
                <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path id="arrow-icon-one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                <path id="arrow-icon-two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                <path id="arrow-icon-three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
                </g>
            </svg>
            </span> 
        </a>
        </div>
        <div className="btn-conteiner">
        <div className="btn-content" role="button" tabindex="0" aria-label="YIEL Farm" onClick={handleFarmClick} onKeyDown={(e) => { if (e.key === 'Enter' || e.key === ' ') { handleFarmClick(e); } }}>
            <span className="btn-title">YIELD Farm</span>
            <span className="icon-arrow">
            <svg width="66px" height="43px" viewBox="0 0 66 43" version="1.1">
                <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path id="arrow-icon-one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                <path id="arrow-icon-two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                <path id="arrow-icon-three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
                </g>
            </svg>
            </span> 
        </div>
        </div>

        </div>
        <div className='buttons-together'>
        </div>
        <h1 className='title'>Chain-Fi's values</h1>
        <div className="cards-container">
    <div className="the-card">
        <img src={Excellence} alt="Excellence" />
        <h2>Excellence</h2>
        <p className="card-text">We strive for excellence in everything we do. Our commitment to quality ensures that we deliver what we promise and go the extra mile to exceed expectations. We set high standards for ourselves and continuously seek ways to improve and innovate.</p>
    </div>
    <div className="the-card">
        <img src={Transparency} alt="Transparency" />
        <h2>Transparency</h2>
        <p className="card-text">Transparency is at the core of our values. We believe in open and honest communication with our clients, partners, and team members. By providing clear and accurate information, we build trust and foster collaborative relationships. Transparency enables us to address challenges openly and work towards shared goals.</p>
    </div>
    <div className="the-card">
        <img src={Passion} alt="Passion" />
        <h2>Passion</h2>
        <p className="card-text">Passion drives us to excel in our work and pursue our goals with enthusiasm and determination. We are passionate about what we do and are committed to making a positive impact. Our dedication and energy inspire us to overcome obstacles and achieve success.</p>
    </div>
    <div className="the-card">
        <img src={Project} alt="Innovation" />
        <h2>Innovation</h2>
        <p className="card-text">Innovation is the cornerstone of our success. We embrace new ideas and technologies to stay ahead of the curve and deliver cutting-edge solutions to our clients. Our culture of innovation encourages creativity and collaboration, driving continuous improvement and growth.</p>
    </div>
    <div className="the-card">
        <img src={Dedicated} alt="Dedicated" />
        <h2>Dedication</h2>
        <p className="card-text">Dedication is ingrained in everything we do. We are committed to achieving excellence and exceeding expectations in every aspect of our work. Our unwavering dedication drives us to go the extra mile and deliver results that make a difference.</p>
    </div>
    <div className="the-card">
        <img src={Deal} alt="Deal" />
        <h2>Commitment</h2>
        <p className="card-text">Commitment is the foundation of our success. We are dedicated to delivering on our promises and ensuring the success of our clients and partners. Our commitment to excellence and integrity drives us to continuously strive for improvement and exceed expectations.</p>
    </div>
</div>
<div className='buttons-together'>
<div className="btn-conteiner" onClick={handleAboutClick} aria-label="About us">
          <div className="btn-content">
            <span className="btn-title">About Us</span>
            <span className="icon-arrow">
              <svg width="66px" height="43px" viewBox="0 0 66 43" version="1.1">
                <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path id="arrow-icon-one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                  <path id="arrow-icon-two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                  <path id="arrow-icon-three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
                </g>
              </svg>
            </span>
          </div>
        </div>
        </div>

      <h1 className='title'>Our Team Members</h1>
    <div className='all-cards bottom20px'>
      <div className="card">
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="card-inner">    <img src={DennisReckermann} alt="Dennis Reckermann" className='images-Dennis'/>    
    </div>
</div>
<div className="card">
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="card-inner"> <img src={BarryVanSpronse} alt="Daniel Barry" className='images-Daniel'/>  </div>
</div>
<div className="card">
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="card-inner"> <img src={DeboraReckerman} alt="Debora Reckerman" className='images-Debora'/> </div>
</div>

</div>
<div className='all-cards'>

<div className="card">
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="card-inner"> <img src={DanielAgantem} alt="Daniel Agantem" className='images-Daniel'/>  </div>
</div>
<div className="card">
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="card-inner"> <img src={AlanCharsley} alt="Alan Charsley" className='images-Alan'/> </div>
</div>
</div>
<h1 className='title'>Our Services</h1>
<div className="project-container reverse">
      <div className="description">
          <h2 className="title">Custom Solutions Tailored for You</h2>
          <p className="content">Explore a range of custom solutions crafted to meet your unique business needs. From DAG model implementation to cross-chain technology and smart contract development, we're here to simplify your blockchain journey.</p>
          <h2 className="title">Elevating Your Blockchain Experience</h2>
          <p className="content">Experience seamless transactions and NFT interactions with Chain-Fi. Our GM coin streamlines transactions across blockchains, covering gas fees. Join our community to earn tokens and actively shape our decentralized governance.</p>
          <h2 className="title">Unleashing Innovation</h2>
          <p className="content">Partner with us to transform the digital landscape through DAG protocols, smart contracts, and cross-chain operability. Together, we're pioneering the future of blockchain technology.</p>
      </div>
        <div className="rocket-image">
            <img src={Blocks} alt="Rocket" />
        </div>
        </div>
        <div className='buttons-together'>
        <div className="btn-conteiner" onClick={handleServicesClick} aria-label="Services">
        <div className="btn-content">
            <span className="btn-title">Services</span>
            <span className="icon-arrow">
            <svg width="66px" height="43px" viewBox="0 0 66 43" version="1.1">
                <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path id="arrow-icon-one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                <path id="arrow-icon-two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                <path id="arrow-icon-three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
                </g>
            </svg>
            </span> 
        </div>
        </div>
        </div>
        <h1 className='title'>Our Community</h1>
        <div className="project-container">
        <div className="description">
          <h2 className="title">A Vibrant Community</h2>
          <p className="content">Step into our diverse and dynamic community, where innovation thrives and collaboration knows no bounds. Here, you'll find a melting pot of ideas, expertise, and passion driving our collective journey through the blockchain landscape.</p>
          <h2 className="title">Embrace the Collective Vision</h2>
          <p className="content">Join us in championing the transformative power of blockchain technology. Our community is united by a shared vision of inclusivity, equity, and connectivity, shaping the future of decentralized technology together.</p>
          <h2 className="title">Engage, Learn, and Grow Together</h2>
          <p className="content">Participate in lively discussions, share insights, and expand your knowledge alongside like-minded individuals. Whether you're a seasoned expert or new to the space, our community offers a supportive platform for learning and growth.</p>
        </div>
        <div className="rocket-image">
            <img src={Community} alt="Rocket" />
        </div>
        </div>
        <h1 className='title-contact'></h1>
        <h2 className='subtitle-contact '></h2>
        <h2 className='subtitle-contact '></h2>
      </div>
      {showPDF && (
                <div className="pdf-viewer">
                      <button className="close-button" onClick={handleButtonClick}>Close</button>

                    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
                        <Viewer fileUrl={WP} />
                    </Worker>
                </div>
            )}
    </div>
  );
}

export default HomePage;
