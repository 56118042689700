import { React, useEffect } from 'react';
import '../css/Farm.css';
import Logo from '../images/logo-full.png';
import Questn from '../images/Questn.png';
import Metamask from '../images/Metamask.png';
import Coinbase from '../images/Coinbase.png';
import Discord from '../images/discord.png';
import Chest from '../images/chest.png';
import Sepolia from '../images/Sepolia.png';
import SepoliaPage from '../images/Spolia-page.webp';
import MetaMask1 from '../images/metamask1.webp';
import MetaMask2 from '../images/metamask2.webp';
import Coinbase1 from '../images/coinbase1.webp';
import Coinbase2 from '../images/coinbase2.webp';


import { useNavigate } from 'react-router-dom';

const Farm = () => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/homepage');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page">
      <div className="banner">
        <h1 className='title'>YIELD Farm <img className='chain-fi-logo' src={Logo} alt="Rocket" /></h1>
      </div>
      <div className="content">
      <div className="cards-container">

<div className="the-card">
<img src={Discord} alt="Rogue Token" />
<h2>Start by joining our Discord!</h2>
<p className="card-text">Please click the button below to secure a role you’ll be directly linked to our Discord community via a referral, instantly connecting you with other members and activities.</p>
<ul className="example-2">
<li className="icon-content">
    <a
      href="https://discord.gg/P4k8rn3mCZ" target="_blank" rel="noreferrer"
      aria-label="Discord"
      data-social="Discord"
    >
      <div className="filled"></div>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-discord" viewBox="0 0 16 16">
  <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
</svg>
    </a>
    <div className="tooltip">Discord</div>
  </li>
  </ul>
  </div>

<div className="the-card">
<img src={Questn} alt="Questn" />
<h2>Community Participation</h2>
<p className="card-text">Roles are essential for accessing Token Farm benefits. Engage in discussions, assist others, and contribute to Chain-Fi's growth to elevate your role. Higher roles offer more rewards, like weekly token drops.</p>

<ul className="example-2">
<li className="icon-content">
    <a
      href="https://app.questn.com/chainfi" target="_blank" rel="noreferrer"
      aria-label="QuestN"
      data-social="QuestN"
    >
      <div className="filled"></div>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg>
    </a>
    <div className="tooltip">QuestN</div>
  </li>
  </ul></div>

<div className="the-card">
<img src={Chest} alt="Chest" />
<h2>Chest Minting</h2>
<p className="card-text">Unlock treasure chests filled with 2000 Rogue coins each! Join our exciting chest minting events to claim your share of 2000 Rogue coins. Click the button below to embark on your treasure-hunting journey and claim your first chest.</p>
<ul className="example-2">
<li className="icon-content">
    <a
      href="https://mint.fun/base/0x7bBEb395b711062A5C3BB84D46B9E9127f6Ef607?ref=0x88de26c634E0493477CBd9D2e1822d65c3aAf8e9" target="_blank" rel="noreferrer"
      aria-label="Chest"
      data-social="Chest"
    >
      <div className="filled"></div>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-archive" viewBox="0 0 16 16">
  <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5zm13-3H1v2h14zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5"/>
</svg>
    </a>
    <div className="tooltip">Chest</div>
  </li>
  </ul></div>

</div>
<a className="btn-conteiner" href="https://chainfitestnet.staging-dapp.add3.io/" target="_blank" rel="noreferrer" aria-label="Go To Farm">
          <div className="btn-content">
            <span className="btn-title">Go To Farm</span>
            <span className="icon-arrow">
              <svg width="66px" height="43px" viewBox="0 0 66 43" version="1.1">
                <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path id="arrow-icon-one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                  <path id="arrow-icon-two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                  <path id="arrow-icon-three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
                </g>
              </svg>
            </span>
          </div>
        </a>
        <h1 className='title'>How to start ?</h1>

      <div className="project-container">

  <div className="project-container">
    <div className="description">
      <h2 className="title">Ready to Start Your Journey with Rogue Tokens?</h2>
             <h2>1. Set Up Your Wallet</h2>

      <p className="content">
      Follow our step-by-step guide to setting up your wallet. This will ensure that your wallet is properly configured to receive and manage Rogue tokens.
      </p>

      <h2>2. Acquire ETH Sepolia for Transaction Fees</h2>
      <p className='content'>To engage in transactions, you'll need some ETH Sepolia. If you're unsure how to obtain this, the guide includes detailed instructions, or you can reach out to a community member for assistance—many are willing to help newcomers!</p>

      <h2 className="title">Need help?</h2>
      <p className="content">
        Don't hesitate to ask for help in our community forums or directly reach out through our support channels. We're here to assist you every step of the way.<br />
        Welcome aboard and happy farming!
      </p>
    </div>

  </div>
</div>
<h1 className='title'>Tutorials</h1>

<div className="cards-container">

  <div className="the-card">
    <img src={Metamask} alt="Rogue Token" />
    <h2>Set up your Metamask wallet</h2>
    <a className="btn-conteiner" href="https://metamask.io/download/" target="_blank" rel="noreferrer" aria-label="Download Metamask">
          <div className="btn-content">
            <span className="btn-title">Download</span>
            <span className="icon-arrow">
              <svg width="66px" height="43px" viewBox="0 0 66 43" version="1.1">
                <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path id="arrow-icon-one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                  <path id="arrow-icon-two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                  <path id="arrow-icon-three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
                </g>
              </svg>
            </span>
          </div>
        </a>
    <p className="card-text">
      <ol className="steps-list">
        <li className="step"><strong>Network Selection:</strong>
          <ul className="sub-steps-list">
            <li className="sub-step">Open your MetaMask wallet.</li>
            <li className="sub-step">Click on the network dropdown at the top center.</li>
            <li className="sub-step">Toggle on ‘Show test networks’.</li>
          </ul>
        </li>
        <li className="step"><strong>Selecting Sepolia Testnet:</strong>
          <ul className="sub-steps-list">
            <li className="sub-step">If Sepolia is listed under test networks, click to select it.</li>
            <li className="sub-step">If Sepolia is not listed, you'll need to add it manually.
              <ul className="sub-steps-list">
                <li className="sub-step">Go to <a href="https://chainlist.org/?testnets=true" target="_blank" rel="noreferrer">Chainlist</a> (or similar sites). Make sure the ‘Include Testnets’ checkbox is ticked.</li>
                <li className="sub-step">In the ‘Search Networks’ bar, type ‘Sepolia’ and press ‘Enter’.</li>
                <li className="sub-step">When Sepolia appears, click on ‘Connect Wallet’ and then ‘Add Network’.</li>
              </ul>
            </li>
          </ul>
        </li>
        <li className="step"><strong>Using the Faucet:</strong>
          <ul className="sub-steps-list">
            <li className="sub-step">With Sepolia testnet now active on MetaMask, navigate to the Sepolia Faucet.</li>
            <li className="sub-step">Follow the instructions on the faucet to receive testnet ETH required for transaction gas fees.</li>
          </ul>
        </li>
        <img className='Metamask2' src={MetaMask2} alt="Rocket" />
        <img className='Metamask1' src={MetaMask1} alt="Rocket" />
      </ol>
    </p>
  </div>

  <div className="the-card">
    <img src={Coinbase} alt="Rogue Token" />
    <h2>Set up your Coinbase wallet</h2>
    <a className="btn-conteiner" href="https://www.coinbase.com/wallet/downloads" target="_blank" rel="noreferrer" aria-label="Download Coinbase">
          <div className="btn-content">
            <span className="btn-title">Download</span>
            <span className="icon-arrow">
              <svg width="66px" height="43px" viewBox="0 0 66 43" version="1.1">
                <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path id="arrow-icon-one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                  <path id="arrow-icon-two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                  <path id="arrow-icon-three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
                </g>
              </svg>
            </span>
          </div>
        </a>
    <p className="card-text">
      <ol className="steps-list">
        <li className="step"><strong>Accessing Network Settings:</strong>
          <ul className="sub-steps-list">
            <li className="sub-step">Open your Coinbase Wallet.</li>
            <li className="sub-step">Tap on the ‘Settings’ icon, usually found in the bottom right corner.</li>
            <li className="sub-step">Select ‘Preferences’, then tap on ‘Networks’.</li>
          </ul>
        </li>
        <li className="step"><strong>Enabling Sepolia Testnet:</strong>
          <ul className="sub-steps-list">
            <li className="sub-step">Scroll through the list to find ‘Sepolia’.</li>
            <li className="sub-step">If Sepolia is listed, simply tap to enable it.</li>
            <li className="sub-step">If it is not listed, you will need to manually add it by selecting 'Add Network' and entering the network details from <a href="https://chainlist.org/?testnets=true" target="_blank" rel="noreferrer">Chainlist</a> (or similar sites).</li>
          </ul>
        </li>
        <li className="step"><strong>Activating Testnets:</strong>
          <ul className="sub-steps-list">
            <li className="sub-step">Go back to the main ‘Settings’ menu.</li>
            <li className="sub-step">Select ‘Display’.</li>
            <li className="sub-step">Toggle on the option for ‘Testnets’ to show testnet balances.</li>
          </ul>
        </li>
        <li className="step"><strong>You’re All Set:</strong>
          <ul className="sub-steps-list">
            <li className="sub-step">Your Coinbase Wallet is now configured to work with the Sepolia testnet!</li>
          </ul>
        </li>
        <img className='coinbase1' src={Coinbase1} alt="Rocket" />
        <img className='coinbase2' src={Coinbase2} alt="Rocket" />
      </ol>
    </p>
  </div>
  <div className="the-card">
    <img src={Sepolia} alt="Rogue Token" />
    <h2>Now get some ETH/Sepolia</h2>
    <a className="btn-conteiner" href="https://www.farmingrogue.fun/faucet" target="_blank" rel="noreferrer" aria-label="Alchemy Faucet">
          <div className="btn-content">
            <span className="btn-title">Alchemy Faucet</span>
            <span className="icon-arrow">
              <svg width="66px" height="43px" viewBox="0 0 66 43" version="1.1">
                <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path id="arrow-icon-one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                  <path id="arrow-icon-two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                  <path id="arrow-icon-three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
                </g>
              </svg>
            </span>
          </div>
        </a>
    <p className="card-text">
      Keep in mind you need a credit card to sign-up and 0.001 ETH in your wallet to be able to use the faucet. If that is a problem, check-in with our Discord community or ask an admin for assistance. Please refrain from using the faucet if not needed as it is a free service, and there is no need for large amounts of testnet funds. <br /><br />
      <em>Thank you for your understanding.</em>
    </p>
    <div className='Sepoliapage'>
    <img className='Sepoliapage' src={SepoliaPage} alt="Rocket" />
  </div>
  </div>


</div>
  <h2>Rogue Testnet Token <br /> <a className='tokenid' href="https://sepolia.etherscan.io/address/0xa3bb956C5F8Ce6Fb8386e0EBBE82Cba12bBe6EBD" target="_blank" rel="noreferrer">0xa3bb956C5F8Ce6Fb8386e0EBBE82Cba12bBe6EBD</a></h2>
        <div className="btn-conteiner" onClick={handleHomeClick} aria-label="Home Page">
          <div className="btn-content">
            <span className="btn-title">Home Page</span>
            <span className="icon-arrow">
              <svg width="66px" height="43px" viewBox="0 0 66 43" version="1.1">
                <g id="arrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <path id="arrow-icon-one" d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                  <path id="arrow-icon-two" d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z" fill="#FFFFFF"></path>
                  <path id="arrow-icon-three" d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z" fill="#FFFFFF"></path>
                </g>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Farm;
