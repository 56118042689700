import React, { useState } from 'react';
import Navbar from './Navbar';
import Header from './Header';
import Section from './Section';
import './Dashboard.css';

function Dashboard() {
  const [selectedCategory, setSelectedCategory] = useState('Home');

  return (
    <div className="dashboard">
      <Navbar onSelectCategory={setSelectedCategory} />
      <div className="main">
        <Header />
        <Section category={selectedCategory} />
      </div>
    </div>
  );
}

export default Dashboard;
