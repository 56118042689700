import {React, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import { useNavigate } from 'react-router-dom';

import HomePage from './containers/HomePage';
import AboutPage from './containers/AboutPage';
import Services from './containers/Services';
import Farm from './containers/Farm';
import Footer from './containers/Footer';
import Dashboard from './Dashboard/Dashboard';

function Loading() {
  const navigate = useNavigate();

  useEffect(() => {

    const timeout = setTimeout(() => {
      navigate('/homepage')
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [navigate]);

  return (
    <div className="App-Loading">
      <div className="loading">
        <div className="boxes">
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="box">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div className='App'>
        <Routes>
          <Route path="/" element={<Loading />} />
          <Route path="/homepage" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/farm" element={<Farm />} />
          <Route path="/services" element={<Services />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
        <FooterWrapper />
      </div>
    </Router>
  );
}

function FooterWrapper() {
  const location = useLocation();
  const { pathname } = location;

  const isHomepage = pathname === '/' || pathname === '/dashboard';

  return !isHomepage && <Footer />;
}

export default App;
