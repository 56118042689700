import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import './Ecosystem.css'; 

const Ecosystem = () => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null); 
    const [activeTab, setActiveTab] = useState('deposit');

    const data = [
    { rank: 1, address: '3142rexxxxxx00', quantity: '992,887.889', percentage: '70%', value: '$3,500' },
    { rank: 2, address: '3142rexxxxxx00', quantity: '992,887.889', percentage: '5%', value: '$3,500' },
    { rank: 2, address: '3142rexxxxxx00', quantity: '992,887.889', percentage: '4%', value: '$3,500' },
    { rank: 3, address: '3142rexxxxxx00', quantity: '992,887.889', percentage: '2.1%', value: '$3,500' },
    { rank: 3, address: '3142rexxxxxx00', quantity: '992,887.889', percentage: '1.009%', value: '$3,500' },
    { rank: 4, address: '3142rexxxxxx00', quantity: '992,887.889', percentage: '.67%', value: '$3,500' },
  ];

    useEffect(() => {
        const myChartRef = chartRef.current.getContext("2d");

        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        chartInstance.current = new Chart(myChartRef, {
          type: 'line',
          data: {
              labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
              datasets: [
                  {
                      label: "Coin Price",
                      data: [1, 1.1, 1.05, 1.2, 1.24, 1.16, 1.3],
                      backgroundColor: 'rgba(5, 28, 175, 0.3)',
                      borderColor: 'rgba(25, 48, 202, 1)',
                      borderWidth: 3,
                      fill: true,
                      tension: 0.3,
                      pointRadius: 5,        // Default point radius
                      pointHoverRadius: 7,   // Radius on hover
                      pointBackgroundColor: 'rgba(25, 48, 202, 1)', // Customize point color
                      pointBorderColor: 'white',  // Border color of points
                      pointBorderWidth: 2    // Border width of points
                  }
              ]
          },
          options: {
              scales: {
                  y: {
                      beginAtZero: true,
                      ticks: {
                          color: 'white',
                          font: {
                              size: 14 
                          }
                      }
                  },
                  x: {
                      ticks: {
                          color: 'white',
                          font: {
                              size: 14 
                          }
                      }
                  }
              },
              plugins: {
                  legend: {
                      labels: {
                          color: 'white',
                          font: {
                              size: 14 
                          }
                      }
                  },
                  tooltip: {
                      titleColor: 'white', 
                      bodyColor: 'white'
                  }
              },
              maintainAspectRatio: false,
              interaction: {
                  mode: 'nearest',       
                  intersect: false       
              }
          }
      });
      

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, []);

    return (
        <div className="Ecosystem-dashboard">
            <div className='right-and-left'>
            <div style={{ width: "600px", height: "350px" }}>
                <canvas ref={chartRef} />
            </div>
            <div className="trade-box">
          <div className="tab-buttons">
            <button 
              className={`tab-button ${activeTab === 'deposit' ? 'active' : ''}`} 
              onClick={() => setActiveTab('deposit')}
            >
              Deposit
            </button>
            <button 
              className={`tab-button ${activeTab === 'withdraw' ? 'active' : ''}`} 
              onClick={() => setActiveTab('withdraw')}
            >
              Withdraw
            </button>
          </div>
          {activeTab === 'deposit' && (
            <div className="tab-content">
              <h2>Deposit</h2>
              <p>The lockup period for this contract is 30 days. If you unstake within this period a penalty fee will be applied.</p>
              <label>Rogue</label>
              <input 
                type="number" 
                placeholder="Type here" 
                min="0"
                step="any"
                inputMode="numeric"
                onKeyDown={(e) => {
                    if (!/^\d*$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                    e.preventDefault();
                    }
                }}
                />
              <button className="submit-button">Deposit</button>
            </div>
          )}
          {activeTab === 'withdraw' && (
            <div className="tab-content">
              <h2>Withdraw</h2>
              <label>Rogue</label>
              <input 
                type="number" 
                placeholder="Type here" 
                min="0"
                step="any"
                inputMode="numeric"
                onKeyDown={(e) => {
                    if (!/^\d*$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
                    e.preventDefault();
                    }
                }}
                />
              <button className="submit-button">Withdraw</button>
            </div>
          )}
        </div>
            </div>
            <table className="earning-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Address</th>
            <th>Quantity</th>
            <th>Percentage</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{row.rank}</td>
              <td>{row.address}</td>
              <td>{row.quantity}</td>
              <td>{row.percentage}</td>
              <td>{row.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
        </div>
    );
};

export default Ecosystem;
